<template>
    <v-card>
      <v-row>
        <v-col>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            :search="search"
            class="elevation-1"
            :loading="loading"
            :items-per-page="pages"
            loading-text="Cargando..."
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Clientes</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field 
                  v-model="search" 
                  append-icon="mdi-magnify" 
                  label="Buscar"
                >
                </v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark class="mb-2" @click="iniciar">
                  <v-icon>cached</v-icon>
                </v-btn>
                <v-btn color="primary" dark class="mb-2" @click="dialog=!dialog">
                  <v-icon>add_box</v-icon>
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.advertising="{ item }">
              <div v-if="item.advertising">
                <v-btn
                  class="ma-2"
                  color="success"
                  text
                >
                  <v-icon
                    dark
                    center
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  class="ma-2"
                  color="red"
                  text
                >
                  <v-icon
                    dark
                    center
                  >
                    mdi-cancel
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon color="danger" small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div v-if="dialog">
        <NewClient :dialog="dialog" :editedIdex="editedIdex" :editItem="editedItem" @cerrarDesdeHijo="closeModal"/>
      </div>
    </v-card>
</template>

<script>

import { ApiPay  } from "./ApiPay";
import NewClient from "./NewClient";
import messagesMixin from "../../../mixins/messagesMixin"

export default {

    components:{
        NewClient,
    },
    mixins:[messagesMixin],
    data() {
      return {
        items: [],
        api: new ApiPay(),
        loading: false,
        search: "",  
        editedIdex:-1,
        pages:50,
        headers: [
          { text: "ID", value: "id" },
          {
          text: "Tipo",
          align: "start",
          value: "type_identification",
          },
          {
          text: "Identificación",
          align: "start",
          value: "identification",
          },
          {text:'Nombre',value:'name'},
          {text:'Genero',value:'genre'},
          {text:'Dirección',value:'address'},
          {text:'Email',value:'email',sortable:false},
          {text:'Celular',value:'phone',sortable:false},
          {text:'Publicidad',value:'advertising',sortable:false},
          {text:'Puntos',value:'points',sortable:false},
          {text:'Acciones',value:'actions',sortable:false},
        ],
        editedItem:{
          id:-1,
          type_identification:{text:"CEDULA", value:"CC"},
          identification:"",
          name:"",
          address:"",
          email:"",
          phone:"",
          genre:{text:"Masculino", value:"M"},
          advertising:true,
        },
        defaultItem:{
          id:-1,
          type_identification:{text:"CEDULA", value:"CC"},
          identification:"",
          name:"",
          address:"",
          email:"",
          phone:"",
          genre:{text:"Masculino", value:"M"},
          advertising:true,
        },
        dialog:false,
      }        
    },
    // computed:{
    //     formTitle(){
    //         return (this.editedIdex === -1 ? "Nueva": "Editar") + " Cliente"
    //     },
    // },
    methods: {
        async iniciar() {
          try {
              this.loading = true;
              let params=`enterprise=${localStorage.getItem("SucursalId")}`
              let r = await this.api.getClientesP(params);
              this.items = r;     
          } catch (error) {
              // alert(error);
              // this.msgError(error)
              this.$swal("Error", error.toString())
          } finally {
              this.loading = false;
          }
        },
        closeModal(){
            this.dialog = false
            this.editedIdex = -1
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            });
            this.iniciar()
        },
        editItem(item){
            this.editedIdex = this.items.indexOf(item)
            this.editedItem = Object.assign({},item)
            this.dialog = true
        },
        async deleteItem(item){
        this.$swal({
            title: '¿Está Seguro?',
            html: `Borrar Cliente <br> <b>${item.name} </b>`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Si, Borrarlo!',
            cancelButtonText: 'No, Mantenerlo!',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async(result) => {
            if(result.value){
              let r = await this.api.delClientes(item.id)
              if (r.status === 500){
                this.$swal("Error","No se puede borrar el cliente","error")
              }else{
                this.$swal("Borrado", "Se borro satisfactoriamente el cliente", "success")
                this.iniciar()
              }
            }
        })
        }
    },
    created() {
        this.iniciar();
    },
}
</script>

<style scoped>

</style>